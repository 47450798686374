<template>
  <!-- Main content -->
  <section class="content">
    <!-- 搜索 -->
    <div class="btn-box">
      <el-form :inline="true">
        <el-form-item label="广告位置：">
          <el-select v-model="search_location_type" placeholder="">
            <el-option
              v-for="item in seachLocationList"
              :key="item.type"
              :label="item.name"
              :value="item.type"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否显示：">
          <el-select v-model="search_status" placeholder="">
            <el-option label="全部" value=""></el-option>
            <el-option label="显示" value="1"></el-option>
            <el-option label="隐藏" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="广告描述：">
          <el-input v-model="search_description" placeholder=""></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="seach(1)">搜索</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 添加按钮 -->
    <div class="btn-box">
      <el-button type="primary" size="mini" @click="add"
        >添加活动宣传口</el-button
      >
    </div>
    <!-- 表单 -->
    <el-table
      v-loading="loading"
      :data="advertiseList"
      border
      style="width: 100%"
    >
      <el-table-column
        fixed
        label="编号"
        prop="id"
        width="50"
      ></el-table-column>
      <el-table-column label="安卓跳转链接" width="250">
        <template slot-scope="item">
          {{ item.row.android_redirect_url }}
          <el-button
            type="text"
            size="small"
            v-clipboard:copy="item.row.android_redirect_url"
            v-clipboard:success="copy"
            >复制</el-button
          >
        </template>
</el-table-column>
<el-table-column label="跳转类型" prop="kl">
    <template slot-scope="item">
          {{ item.row.redirect_type | type }}
        </template>
</el-table-column>
<el-table-column label="携带参数" prop="bind_params"></el-table-column>
<el-table-column label="图片地址">
    <template slot-scope="item">
          <el-image
            :src="item.row.img_url"
            :preview-src-list="[item.row.img_url]"
          ></el-image>
        </template>
</el-table-column>
<el-table-column label="广告描述" prop="description"></el-table-column>

<el-table-column label="广告位置" prop="kl">
    <template slot-scope="item">
          {{ item.row.location_type | location }}
        </template>
</el-table-column>
<el-table-column label="点击次数" prop="click_times"></el-table-column>
<el-table-column label="曝光次数" prop="exposure_times"></el-table-column>
<el-table-column label="广告权重" prop="weight">
    <template slot-scope="item">
    <div v-if="item.row.edit">
        <el-input  v-model="item.row.weight" placeholder=""></el-input>
    </div>
    <span v-else>{{item.row.weight}}</span>
    <el-button type="text" @click="updateActivityWeight(item.row)">{{!item.row.edit?'修改':'确认'}}</el-button>
    </template>
</el-table-column>
<el-table-column label="是否显示">
    <template slot-scope="item">
          {{ item.row.status | status }}
        </template>
</el-table-column>
<el-table-column fixed="right" label="操作">
    <template slot-scope="item">
          <el-button
            type="primary"
            size="mini"
            @click="getAdvertiseData(item.row.id)"
            >修改</el-button
          >
          <el-button
            type="danger"
            size="mini"
            @click="delAdvertise(item.row.id)"
            >删除</el-button
          >
        </template>
</el-table-column>
</el-table>
<!-- 分页 -->
<el-pagination background layout="total, sizes,prev, pager, next,jumper" :total="total" @size-change="sizeChange" @current-change="currentChange" :current-page="page">
</el-pagination>
<!-- 修改添加弹窗 -->
<el-dialog title="添加/修改" :visible.sync="dialog" :close-on-click-modal="false">
    <el-form>
        <el-form-item label="安卓跳转链接">
            <el-input v-model="android_redirect_url" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="IOS跳转链接">
            <el-input v-model="ios_redirect_url" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="跳转类型">
            <el-select v-model="redirect_type" placeholder="">
                <el-option v-for="item in classifyList" :key="item.type" :label="item.name" :value="item.type"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="携带参数">
            <el-input v-model="bind_params" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="商品图片">
            <upLoadText @size="imgSize" id="up" v-model="img_url"></upLoadText>
        </el-form-item>
        <el-form-item label="跳转标题">
            <el-input v-model="redirect_title" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="广告位置">
            <el-select v-model="location_type" placeholder="">
                <el-option v-for="item in locationList" :key="item.type" :label="item.name" :value="item.type"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="时间范围">
            <el-date-picker v-model="start_time" value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="选择日期时间">
            </el-date-picker>
            -
            <el-date-picker v-model="end_time" value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="选择日期时间">
            </el-date-picker>
            <el-button type="primary" @click="add_td_tiem">特定时间</el-button>
        </el-form-item>

        <el-form-item label="" v-for="(item,index) in specific_time" :key="index">
            <el-time-picker is-range v-model="specific_time[index]" value-format="HH:mm" format="HH:mm" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" placeholder="选择时间范围">
            </el-time-picker>
            <el-button type="danger" @click="del_specific_time_item(index)">删除</el-button>
        </el-form-item>

        <el-form-item label="是否显示">
            <el-radio-group v-model="status">
                <el-radio label="1">显示</el-radio>
                <el-radio label="2">隐藏</el-radio>
            </el-radio-group>
        </el-form-item>
        <el-form-item label="广告权重">
            <el-input v-model="weight" type="number" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="广告描述">
            <el-input v-model="description" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="显示内容">
            <el-input v-model="show_content" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="显示类型">
            <el-radio-group v-model="show_type">
                <el-radio v-for="item in showList" :key="item.type" :label="item.type">{{ item.name }}</el-radio>
            </el-radio-group>
        </el-form-item>
        <el-form-item label="版本限制">
            <el-radio-group v-model="version_type">
                <el-radio v-for="item in compareList" :key="item.type" :label="item.type">{{ item.name }}</el-radio>
            </el-radio-group>
        </el-form-item>
        <el-form-item label="版本号">
            <el-input v-model="version" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="用户分群">
            <el-select v-model="group_id" multiple placeholder="">
                <el-option v-for="item in groupList" :key="item.type" :label="item.name" :value="item.type"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="审核模式是否显示">
            <el-radio-group v-model="is_show">
                <el-radio label="1">显示</el-radio>
                <el-radio label="2">隐藏</el-radio>
            </el-radio-group>
        </el-form-item>
        <el-form-item label="行为">
            <el-select v-model="behavior_type" placeholder="">
                <el-option v-for="item in behaviorList" :key="item.type" :label="item.name" :value="item.type"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="最近x天">
            <el-input v-model="day_number" placeholder=""></el-input>
        </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
        <el-button @click="dialog = false">取 消</el-button>
        <el-button type="primary" @click="alter">确 定</el-button>
    </div>
</el-dialog>
</section>
</template>
<script>
    let common = JSON.parse(localStorage.getItem("common"));
    import * as api from "@/config/api";
    export default {
        name: "transfer",
        data() {
            return {
                loading: false,
                pagesize: 10,
                page: 1,
                total: 0,

                groupList: common.user_group_list,
                behaviorList: [...[{
                    type: '0',
                    name: '不限'
                }], ...common.user_behavior_list],
                compareList: common.compare_type_list,
                classifyList: common.redirect_type_list,
                seachLocationList: [...[{
                    type: '',
                    name: '全部'
                }], ...common.location_type_list],
                locationList: common.location_type_list,
                showList: common.show_type_list,

                dialog: false,
                advertiseList: [],

                search_location_type: "",
                search_status: "",
                search_description: "",

                id: "",
                android_redirect_url: "",
                ios_redirect_url: "",
                redirect_type: "",
                bind_params: "",
                img_url: "",
                redirect_title: "",
                location_type: "",
                start_time: "",
                end_time: "",
                status: "",
                weight: "",
                description: "",
                show_content: "",
                show_type: "",
                is_show: "",
                version_type: "",
                version: "",
                group_id: "",
                day_number: "",
                behavior_type: "",
                specific_time: [
                    ['', '']
                ],
                width: '',
                height: ''
            };
        },
        filters: {
            status(val) {
                switch (Number(val)) {
                    case 1:
                        return "显示";
                        break;
                    default:
                        return "隐藏";
                        break;
                }
            },
            type(val) {
                let name = "";
                if (JSON.stringify(common.redirect_type_list).indexOf(val) != -1) {
                    common.redirect_type_list.map((a, index) => {
                        if (val === a.type) {
                            name = a.name;
                        }
                    });
                }
                return name;
            },
            location(val) {
                let name = "";
                if (JSON.stringify(common.location_type_list).indexOf(val) != -1) {
                    common.location_type_list.map((a, index) => {
                        if (val === a.type) {
                            name = a.name;
                        }
                    });
                }
                return name;
            },
        },
        watch: {
            start_time(val) {
                console.log(val)
            }
        },
        mounted() {
            this.getAdvertiseList();
        },
        methods: {
            //   删除特定时间
            del_specific_time_item(index) {
                this.specific_time.splice(index, 1);
            },
            //   添加特定时间
            add_td_tiem() {
                this.specific_time.push(['', ''])
            },
            getTime(time) {
                let Time = new Date(time * 1000);
                return `${Time.getFullYear()}-${Time.getMonth() + 1 < 10 ? "0" + (Time.getMonth() + 1) : Time.getMonth() + 1}-${Time.getDate() < 10 ? "0" + Time.getDate() : Time.getDate()} ${Time.getHours() < 10 ? "0" + Time.getHours() : Time.getHours()}:${Time.getMinutes() < 10 ? "0" + Time.getMinutes() : Time.getMinutes()}:${Time.getSeconds() < 10 ? "0" + Time.getSeconds() : Time.getSeconds()}`
            },
            // 获取列表
            getAdvertiseList(page, size) {
                api.getAdvertiseList({
                        page: page ? page : this.page,
                        pagesize: size ? size : this.pagesize,
                        location_type: this.search_location_type,
                        status: this.search_status,
                        description: this.search_description,
                    },
                    (res) => {
                        res.data.list.forEach(element => {
                            element.edit = false
                        });
                        this.advertiseList = res.data.list;
                        this.total = Number(res.data.count);
                    }
                );
            },
            // 分页点击
            sizeChange(val) {
                this.pagesize = val;
                this.getAdvertiseList();
            },
            currentChange(val) {
                this.page = val;
                this.getAdvertiseList();
            },
            //搜索
            seach(page) {
                this.page = page;
                this.getAdvertiseList(page);
            },
            reset(page) {
                this.search_id = "";
                this.page = page;
                this.getAdvertiseList(page);
            },
            // 复制
            copy() {
                this.$message({
                    message: "复制成功",
                    type: "success",
                });
            },
            // 上传图片
            imgSize(val) {
                console.log(val)
                this.width = val.width
                this.height = val.height
            },
            // 获取详情
            getAdvertiseData(id) {
                this.id = id;
                api.getAdvertiseData({
                        id: id,
                    },
                    (res) => {
                        this.android_redirect_url = res.data.android_redirect_url;
                        this.ios_redirect_url = res.data.ios_redirect_url;
                        this.redirect_type = res.data.redirect_type;
                        this.bind_params = res.data.bind_params;
                        this.img_url = res.data.img_url;
                        this.redirect_title = res.data.redirect_title;
                        this.location_type = res.data.location_type;
                        this.start_time = Number(res.data.start_time) != 0 ? this.getTime(Number(res.data.start_time)) : '';
                        this.end_time = Number(res.data.end_time) != 0 ? this.getTime(Number(res.data.end_time)) : '';
                        this.status = res.data.status;
                        this.weight = res.data.weight;
                        this.description = res.data.description;
                        this.show_content = res.data.show_content;
                        this.show_type = res.data.show_type;
                        this.is_show = res.data.is_show;
                        this.version_type = res.data.version_type;
                        this.version = res.data.version;
                        this.group_id = res.data.group_id != '' ? res.data.group_id.split(',') : ''
                        this.day_number = res.data.day_number;
                        this.behavior_type = res.data.behavior_type;
                        this.dialog = true;
                        console.log(res.data.specific_time);
                        let specific_time = res.data.specific_time;
                        let specific_time_arr = [];
                        if (specific_time) {
                            specific_time.forEach((item, index) => {
                                specific_time_arr.push([item.start_time, item.end_time]);
                            });
                            this.specific_time = specific_time_arr;
                        }
                        this.width = res.data.width
                        this.height = res.data.height
                    }
                );
            },
            // 添加选品
            add() {
                this.id = "";
                this.android_redirect_url = "";
                this.ios_redirect_url = "";
                this.redirect_type = "";
                this.bind_params = "";
                this.img_url = "";
                this.redirect_title = "";
                this.location_type = "";
                this.start_time = "";
                this.end_time = "";
                this.status = "1";
                this.weight = "";
                this.description = "";
                this.show_content = "";
                this.show_type = "2";
                this.is_show = "2";
                this.version_type = "0";
                this.version = "";
                this.group_id = "";
                this.day_number = "";
                this.behavior_type = "";
                this.dialog = true;
                this.specific_time = [];
                this.width = '',
                    this.height = ''
            },
            // 修改、添加频道分类
            alter() {
                let specific_time = [];
                this.specific_time.forEach(item => {
                    specific_time.push({
                        start_time: item[0],
                        end_time: item[1],
                    })
                })
                if (this.id != "") {
                    api.updateAdvertise({
                            id: this.id,
                            android_redirect_url: this.android_redirect_url,
                            ios_redirect_url: this.ios_redirect_url,
                            redirect_type: this.redirect_type,
                            bind_params: this.bind_params,
                            img_url: this.img_url,
                            redirect_title: this.redirect_title,
                            location_type: this.location_type,
                            start_time: this.start_time,
                            end_time: this.end_time,
                            status: this.status,
                            weight: this.weight,
                            description: this.description,
                            show_content: this.show_content,
                            show_type: this.show_type,
                            is_show: this.is_show,
                            version_type: this.version_type,
                            version: this.version,
                            group_id: this.group_id,
                            day_number: this.day_number,
                            behavior_type: this.behavior_type,
                            specific_time,
                            width: this.width,
                            height: this.height
                        },
                        (res) => {
                            this.$message({
                                message: res.msg,
                                type: "success",
                            });
                            this.dialog = false;
                            this.getAdvertiseList();
                        }
                    );
                    return;
                }
                api.addAdvertise({
                        android_redirect_url: this.android_redirect_url,
                        ios_redirect_url: this.ios_redirect_url,
                        redirect_type: this.redirect_type,
                        bind_params: this.bind_params,
                        img_url: this.img_url,
                        redirect_title: this.redirect_title,
                        location_type: this.location_type,
                        start_time: this.start_time,
                        end_time: this.end_time,
                        status: this.status,
                        weight: this.weight,
                        description: this.description,
                        show_content: this.show_content,
                        show_type: this.show_type,
                        is_show: this.is_show,
                        version_type: this.version_type,
                        version: this.version,
                        group_id: this.group_id,
                        day_number: this.day_number,
                        behavior_type: this.behavior_type,
                        specific_time,
                        width: this.width,
                        height: this.height
                    },
                    (res) => {
                        this.$message({
                            message: res.msg,
                            type: "success",
                        });
                        this.dialog = false;
                        this.getAdvertiseList();
                    }
                );
            },
            delAdvertise(id) {
                this.$confirm("是否确认删除该活动宣传口?", "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                    })
                    .then(() => {
                        api.delAdvertise({
                                id: id,
                            },
                            (res) => {
                                this.$message({
                                    type: "success",
                                    message: "删除成功!",
                                });
                                this.getAdvertiseList();
                            }
                        );
                    })
                    .catch(() => {});
            },
            updateActivityWeight(row) {
                if (!row.edit) {
                    row.edit = true
                    return
                }
                api.updateActivityWeight({
                    id: row.id,
                    weight: row.weight
                }, res => {
                    this.getAdvertiseList()
                })
            }
        },
    };
</script>
<style lang="scss">
    .btn-box {
        text-align: left;
        margin-bottom: 20px;
    }
    
    .el-image img {
        height: 40px;
    }
    
    .el-image-viewer__canvas img {
        width: auto;
        height: auto;
    }
</style>